import React from "react";
import { Layout, Seo } from "components/common";
import {Button} from "components/common";
import {Header} from "../components/theme";

const NotFound = () => {
	const toHome = () => {
		const pathname = window.location.pathname
		window.location.pathname = pathname.startsWith("/cn") ? "/cn/" : "/"
	}
	return (
		<Layout lang={"en"}>
			<Seo title="404: Not found" location="/404" />
			<Header lang={"en"}/>
			<div style={{padding: "0 auto", textAlign: "center", color: "black", background: "#fff", fontSize: "30px"}}>
				<h1 style={{paddingTop: "10rem"}}>NOT FOUND</h1>
				<h1>You just hit a route that doesn&#39;t exist...</h1>
				<Button onClick={toHome}>Homepage</Button>
				<h1 style={{paddingTop: "3rem"}}>404 未找到指定页面</h1>
				<Button onClick={toHome}>返回主页</Button>
			</div>
		</Layout>
	)
}

export default NotFound;
